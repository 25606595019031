import { AssetManagerStatusType, ManagementPositionType } from './lib/rpc-shared-model'
import { ChipOption, Option } from './option'

export const MANAGER_POSITION_OPTIONS: ReadonlyArray<Option<ManagementPositionType>> = [
  {
    label: 'CEO',
    value: ManagementPositionType.ChiefExecutiveOfficer,
  },
  {
    label: 'Co-CEO',
    value: ManagementPositionType.CoChiefExecutiveOfficer,
  },
  {
    label: 'Managing director',
    value: ManagementPositionType.ManagingDirector,
  },
  {
    label: 'Director',
    value: ManagementPositionType.Director,
  },
  {
    label: 'Chair',
    value: ManagementPositionType.Chairman,
  },
  {
    label: 'Board member',
    value: ManagementPositionType.BoardMember,
  },
  {
    label: 'Representative',
    value: ManagementPositionType.Representative,
  },
]

export const MANAGER_STATUS_OPTIONS: ReadonlyArray<ChipOption<AssetManagerStatusType>> = [
  {
    label: 'Valid',
    value: AssetManagerStatusType.Valid,
    color: 'success',
  },
  {
    label: 'Invalid',
    value: AssetManagerStatusType.Invalid,
    color: 'error',
  },
  {
    label: 'To validate',
    value: AssetManagerStatusType.ToValidate,
    color: 'info',
  },
]
