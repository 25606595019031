import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { PropsWithChildren, ReactNode } from 'react'

import ConsistencyGuideButton from './consistency-guide-button'

export interface ItemPageBlockProps {
  label?: string
  consistencyGuideName?: string
  children: ReactNode
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 2, 1, 2),
  gap: theme.spacing(2),
}))

export function ItemPageBlock({
  label,
  children,
  consistencyGuideName,
}: PropsWithChildren<ItemPageBlockProps>) {
  return (
    <Card>
      <CardHeader
        title={
          <Stack
            alignItems={'center'}
            flexDirection={'row'}
            gap={0.5}>
            <Typography variant={'inherit'}>{label}</Typography>
            <ConsistencyGuideButton
              consistencyGuideName={consistencyGuideName}
              label={label}
            />
          </Stack>
        }
      />
      <StyledCardContent>{children}</StyledCardContent>
    </Card>
  )
}
