import { useRpcClient } from '@gain/api/swr'
import { CreditListItem, RpcMethodMap } from '@gain/rpc/cms-model'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import React, { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import InputForm from '../../common/input-fields'
import SwrDataGrid from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import CmsLink from '../../features/cms-link'
import LayoutDataGrid from '../../layout/data-grid'
import { CREDIT_GENERAL_PAGE_PATH } from '../route-credit'
import ImportCreditsButton from './import-credits-button/import-credits-button'

export default function RouteCredits() {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const history = useHistory()

  // TODO: don't create a new credit on each click
  const handleCreatNewCredit = useCallback(async () => {
    const response = await rpcClient({
      method: 'data.createCredit',
      params: {
        partial: {},
      },
    })

    if (response && response?.id) {
      history.push(generatePath(CREDIT_GENERAL_PAGE_PATH, { id: response.id }))
    }
  }, [history, rpcClient])

  return (
    <LayoutDataGrid title={'Credits'}>
      <Card>
        <SwrDataGrid<'data.listCredits', CreditListItem>
          actions={[
            <Button
              key={'create-new-credit-action'}
              onClick={handleCreatNewCredit}
              variant={'contained'}>
              New
            </Button>,
            <ImportCreditsButton key={'upload-credits-action'} />,
          ]}
          columns={[
            {
              field: 'linkedAssetName',
              headerName: 'Target',
              flex: 1,
              minWidth: 150,
              filterable: false,
              renderCell: ({ row }) => (
                <CmsLink
                  key={row.linkedAsset?.id}
                  id={row.linkedAsset?.id}
                  label={row.linkedAsset?.name}
                  type={'asset'}
                />
              ),
            },
            {
              field: 'linkedLenderNames',
              headerName: 'Lenders',
              flex: 1,
              minWidth: 150,
              filterable: false,
              renderCell: ({ row }) => (
                <Stack
                  display={'inline-flex'}
                  flexDirection={'row'}
                  gap={0.5}>
                  {row.linkedLenders.map((lender) => (
                    <CmsLink
                      key={lender.id}
                      id={lender.id}
                      label={lender.name}
                      type={'lender'}
                    />
                  ))}
                </Stack>
              ),
            },
            {
              field: 'live',
              headerName: 'Status',
              minWidth: 150,
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Published' : 'Draft'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'publishedAt',
              headerName: 'Published at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'unpublishedAt',
              headerName: 'Unpublished at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },
          ]}
          label={'Credits'}
          method={'data.listCredits'}
          path={':id'}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              deleteMethod={'data.deleteCredit'}
              isDisabled={() => row.live}
              name={'credit'}
              publishMethod={'data.publishCredit'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishCredit'}
              validateMethod={'data.validateCredit'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'updatedAt', direction: 'desc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
