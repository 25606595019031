const CURRENT_COMPANY = 'currentCompany'

const isNumeric = (value: string) => /^-?\d+$/.test(value)

// Extract a LinkedIn company ID from a URL in the following format:
// https://www.linkedin.com/search/results/people/?currentCompany=%5B%221578249%22%5D&origin=COMPANY_PAGE_CANNED_SEARCH&lipi=urn%3Ali%3Apage%3Acompanies_company_about_index%3B29a82c2e-1d88-4edc-b080-081965997d18
export function getLinkedinExternalId(urlString: string): number | undefined {
  if (!urlString) {
    return undefined
  }

  // If it's a number, assume that it's a company ID
  if (isNumeric(urlString)) {
    return parseInt(urlString)
  }

  try {
    const url = new URL(urlString)

    // Verify that it's a LinkedIn URL
    if (url.host !== 'www.linkedin.com') {
      return undefined
    }

    // Extract current company search param
    const currentCompany = url.searchParams.get(CURRENT_COMPANY)
    if (!currentCompany) {
      return undefined
    }

    // Parse value to a number. Values can be in the form of: '["1578249"]', or '1578249'
    const parsed = JSON.parse(currentCompany)
    if (Array.isArray(parsed)) {
      return parseInt(parsed[0])
    }

    return parseInt(parsed)
  } catch {
    return undefined
  }
}

export function getLinkedInCompanyUrl(linkedInCompanyId: string | undefined): string | undefined {
  if (typeof linkedInCompanyId !== 'string') {
    return undefined
  }

  return `https://www.linkedin.com/company/${linkedInCompanyId}`
}
