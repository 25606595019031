import {
  CreditReferenceRate,
  CreditStatus,
  CreditSubtype,
  CreditType,
} from './lib/rpc-shared-model'
import { Option } from './option'

export const CREDIT_STATUS_OPTIONS: ReadonlyArray<Option<CreditStatus>> = [
  {
    label: 'Live',
    value: CreditStatus.Live,
  },
  {
    label: 'Matured',
    value: CreditStatus.Matured,
  },
]

export const CREDIT_TYPE_OPTIONS: ReadonlyArray<Option<CreditType>> = [
  {
    label: 'Senior debt',
    value: CreditType.SeniorDebt,
  },
  {
    label: 'Equity',
    value: CreditType.Equity,
  },
]

export const CREDIT_SUBTYPE_OPTIONS: ReadonlyArray<Option<CreditSubtype>> = [
  {
    label: 'First-lien',
    value: CreditSubtype.FirstLien,
  },
  {
    label: 'Second-lien',
    value: CreditSubtype.SecondLien,
  },
  {
    label: 'Subordinated loan',
    value: CreditSubtype.SubordinatedLoan,
  },
]

export const CREDIT_REFERENCE_RATE_OPTIONS: ReadonlyArray<Option<CreditReferenceRate>> = [
  {
    label: 'SONIA',
    value: CreditReferenceRate.Sonia,
  },
  {
    label: 'SOFR',
    value: CreditReferenceRate.Sofr,
  },
]
