import { SkippedCreditItem } from '@gain/rpc/cms-model'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import React from 'react'

const StyledUl = styled('ul')({
  padding: 0,
})

interface SkippedItemsProps {
  totalRows: number
  skippedItems: SkippedCreditItem[]
}

export default function SkippedItems({ totalRows, skippedItems }: SkippedItemsProps) {
  return (
    <Stack
      gap={1}
      sx={{ py: 2 }}>
      <Typography variant={'body2'}>
        Skipped {skippedItems.length}/{totalRows} credits.
      </Typography>
      <Table
        size={'small'}
        sx={{ border: '1px solid black', borderColor: 'divider', borderRadius: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell>Issues</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skippedItems.map((item) => (
            <TableRow key={item.rowIndex}>
              <TableCell>{item.rowIndex}</TableCell>
              <TableCell>
                <StyledUl>
                  {item.issues.map((issue, index) => (
                    <li key={index}>{issue}</li>
                  ))}
                </StyledUl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  )
}
