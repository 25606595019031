import { ChipProps } from '@mui/material/Chip'
import { ComponentType } from 'react'

export interface Option<Value> {
  value: Value
  label: string
  showDividerAbove?: boolean
  icon?: ComponentType
}

export interface OptionGroup<Value> {
  label: string
  options: Option<Value>[]
}

export interface ChipOption<Value> extends Option<Value> {
  color: ChipProps['color']
}

export function isOptionGroup<T>(value: OptionGroup<T> | Option<T>): value is OptionGroup<T> {
  return 'options' in value
}

export function getOption<Value>(
  options: ReadonlyArray<Option<Value>>,
  value: Value
): Option<Value> | undefined {
  return options.find((option) => option.value === value)
}

export function getLabelFromOption<Value>(
  options: ReadonlyArray<Option<Value>>,
  value: Value,
  emptyValue?: string
): string | undefined {
  return getOption(options, value)?.label || emptyValue
}

/**
 * An RPC validation error might contain an array of validation issues in the
 * data prop. Each issue indicates a type, (json) path to the issue and a
 * descriptive message.
 */
export interface ValidationIssue {
  type: string
  path: string
  message: string
}
