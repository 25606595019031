import { ImportedCreditItem } from '@gain/rpc/cms-model'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import React from 'react'

import CmsLink from '../../../features/cms-link'

interface ImportedItemsProps {
  importedItems: ImportedCreditItem[]
  totalRows: number
}

export default function ImportedItems({ importedItems, totalRows }: ImportedItemsProps) {
  return (
    <Stack
      gap={1}
      sx={{ py: 2 }}>
      <Typography variant={'body2'}>
        Imported {importedItems.length}/{totalRows} credits.
      </Typography>
      <Table
        size={'small'}
        sx={{ border: '1px solid black', borderColor: 'divider', borderRadius: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell>Credit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {importedItems.map((item) => (
            <TableRow key={item.rowIndex}>
              <TableCell>{item.rowIndex}</TableCell>
              <TableCell>
                <CmsLink
                  id={item.credit.id}
                  label={'View credit'}
                  type={'credit'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  )
}
