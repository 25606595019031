import { useRpcClient } from '@gain/api/swr'
import { useFileUpload } from '@gain/cms/api'
import { ImportCreditsResult, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import ImportedItems from './imported-items'
import SkippedItems from './skipped-items'

export default function ImportCreditsDialog(props: DialogProps) {
  const upload = useFileUpload()
  const rpc = useRpcClient<RpcMethodMap>()
  const [result, setResult] = useState<ImportCreditsResult | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [visibleTab, setVisibleTab] = useState<number>(0)

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length !== 1) {
        return
      }
      setLoading(true)
      setError(null)
      const fileId = await upload(acceptedFiles[0], { secure: true })

      if (typeof fileId === 'number') {
        try {
          const response = await rpc({
            method: 'data.importCredits',
            params: {
              fileId,
            },
          })
          setResult(response)
        } catch (e) {
          if (isJsonRpcError(e)) {
            setError(e.message)
          }
        }
      }

      setLoading(false)
    },
    [upload, rpc]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  })

  useEffect(() => {
    if (!props.open) {
      setLoading(false)
      setResult(null)
      setVisibleTab(0)
    }
  }, [props.open])

  return (
    <Dialog {...props}>
      <DialogTitle>Import lender credits</DialogTitle>
      <DialogContent>
        {result === null && (
          <Stack gap={2}>
            <Typography variant={'body2'}>
              Import credits given a CSV file. Download the example below to view the formatting of
              the CSV file.
            </Typography>

            {error !== null && <Alert severity={'error'}>{error}</Alert>}

            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}>
              {loading && <CircularProgress size={16} />}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <Button
                    disabled={loading}
                    variant={'contained'}>
                    Select file
                  </Button>
                )}
              </div>
              <Button
                href={'/excel/import-lender-credits-example.csv'}
                target={'_blank'}>
                Download example
              </Button>
            </Stack>
          </Stack>
        )}
        {result !== null && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={(_, newValue) => setVisibleTab(newValue)}
                value={visibleTab}>
                <Tab
                  label={'Imported'}
                  value={0}
                />
                <Tab
                  label={'Skipped'}
                  value={1}
                />
              </Tabs>
            </Box>
            {visibleTab === 0 && (
              <ImportedItems
                importedItems={result.importedItems}
                totalRows={result.rowCount}
              />
            )}
            {visibleTab === 1 && (
              <SkippedItems
                skippedItems={result.skippedItems}
                totalRows={result.rowCount}
              />
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
