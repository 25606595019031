import { useUserProfile } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { UserContextProvider } from '@gain/modules/user'
import { isProduction } from '@gain/utils/environment'
import { isAdministrator } from '@gain/utils/user'
import { styled } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router'

import NotLive from '../Components/NotLive/NotLive'
import { loadCurrencies } from '../redux/action/loadCurrencies'
import { loadSectors } from '../redux/action/loadSectors'
import { loadSubSectors } from '../redux/action/loadSubSectors'
import { ITEM_PATH, LIST_PATH } from '../util/path'
import { appRpcClient } from '../util/rpcClient'
import ItemRoutes from './item-routes'
import ListRoutes from './list-routes'
import RouteAdvisor, { ADVISOR_TABS_PAGE_PATH } from './route-advisor'
import RouteAdvisors, { ROUTE_ADVISORS_PATH } from './route-advisors'
import RouteAsset, { ROUTE_ASSET_TABS_PATH } from './route-asset'
import RouteAssets, { ROUTE_ASSETS_PATH } from './route-assets'
import RouteConferenceEdition, {
  CONFERENCE_EDITION_TABS_PAGE_PATH,
} from './route-conference-edition'
import RouteConferenceEditions, {
  ROUTE_CONFERENCE_EDITIONS_PATH,
} from './route-conference-editions'
import RouteConferences, { ROUTE_CONFERENCES_PATH } from './route-conferences'
import RouteCredit, { CREDIT_TABS_PAGE_PATH } from './route-credit'
import RouteCredits from './route-credits/route-credits'
import { ROUTE_CREDITS_PATH } from './route-credits/route-credits-path'
import RouteDeal, { ROUTE_DEAL_TABS_PATH } from './route-deal'
import RouteDeals, { ROUTE_DEALS_PATH } from './route-deals'
import RouteInvestor, { ROUTE_INVESTOR_TABS_PATH } from './route-investor'
import RouteInvestors, { ROUTE_INVESTORS_PATH } from './route-investors'
import RouteLender, { LENDER_TABS_PAGE_PATH } from './route-lender'
import RouteLenders from './route-lenders/route-lenders'
import { ROUTE_LENDERS_PATH } from './route-lenders/route-lenders-path'
import RouteNotFound from './route-not-found'
import RoutePerson, { ROUTE_PERSON_TABS_PATH } from './route-person'
import RoutePersons, { ROUTE_PERSONS_PATH } from './route-persons'
import RouteProjects, { ROUTE_PROJECTS_PATH } from './route-projects'
import RouteTags, { ROUTE_TAGS_PATH } from './route-tags'
import RouteUsers, { ROUTE_USERS_PATH } from './route-users'
import RouteVenues, { ROUTE_VENUES_PATH } from './route-venues'

const StyledContainer = styled('div')({
  maxWidth: '100vw', // Ensure that the container doesn't overflow the viewport
})

export default function PrivateRoutes() {
  const appRpClientFetcher = useRpcClient(appRpcClient)
  const userProfile = useUserProfile(undefined, { fetcher: appRpClientFetcher })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCurrencies())
    dispatch(loadSectors())
    dispatch(loadSubSectors())

    if (userProfile?.data?.id) {
      cloudErrorReporter.setUser(`${userProfile.data.id}`)
    }
  }, [dispatch, userProfile])

  return (
    <UserContextProvider userProfile={userProfile.data}>
      <StyledContainer id={'container'}>
        {!isProduction() && <NotLive />}

        <React.Suspense fallback={<div />}>
          <Switch>
            <Route
              component={ListRoutes}
              path={LIST_PATH}
            />

            <Route
              component={RouteAssets}
              path={ROUTE_ASSETS_PATH}
              exact
            />

            <Route
              component={RouteAsset}
              path={ROUTE_ASSET_TABS_PATH}
            />

            <Route
              component={ItemRoutes}
              path={ITEM_PATH}
            />

            {isAdministrator(userProfile.data?.role) && (
              <Route
                component={RouteTags}
                path={ROUTE_TAGS_PATH}
              />
            )}

            <Route
              component={RouteAdvisors}
              path={ROUTE_ADVISORS_PATH}
              exact
            />

            <Route
              component={RouteAdvisor}
              path={ADVISOR_TABS_PAGE_PATH}
              exact
            />

            <Route
              component={RouteCredits}
              path={ROUTE_CREDITS_PATH}
              exact
            />

            <Route
              component={RouteCredit}
              path={CREDIT_TABS_PAGE_PATH}
              exact
            />

            <Route
              component={RouteLenders}
              path={ROUTE_LENDERS_PATH}
              exact
            />

            <Route
              component={RouteLender}
              path={LENDER_TABS_PAGE_PATH}
              exact
            />

            <Route
              component={RoutePersons}
              path={ROUTE_PERSONS_PATH}
              exact
            />

            <Route
              component={RoutePerson}
              path={ROUTE_PERSON_TABS_PATH}
              exact
            />

            <Route
              component={RouteUsers}
              path={ROUTE_USERS_PATH}
            />

            <Route
              component={RouteProjects}
              path={ROUTE_PROJECTS_PATH}
            />

            <Route
              component={RouteVenues}
              path={ROUTE_VENUES_PATH}
            />

            <Route
              component={RouteConferences}
              path={ROUTE_CONFERENCES_PATH}
            />

            <Route
              component={RouteConferenceEditions}
              path={ROUTE_CONFERENCE_EDITIONS_PATH}
              exact
            />

            <Route
              component={RouteConferenceEdition}
              path={CONFERENCE_EDITION_TABS_PAGE_PATH}
            />

            <Route
              component={RouteInvestors}
              path={ROUTE_INVESTORS_PATH}
              exact
            />

            <Route
              component={RouteInvestor}
              path={ROUTE_INVESTOR_TABS_PATH}
            />

            <Route
              component={RouteDeals}
              path={ROUTE_DEALS_PATH}
              exact
            />

            <Route
              component={RouteDeal}
              path={ROUTE_DEAL_TABS_PATH}
            />

            <Route component={RouteNotFound} />
          </Switch>
        </React.Suspense>
      </StyledContainer>
    </UserContextProvider>
  )
}
