import React from 'react'

import { getLinkedInCompanyUrl, getLinkedinExternalId } from '../linkedin-utils'
import { InputEndAdornmentHref, InputFieldText } from './index'
import { InputFieldTransform } from './input-field-transform'

interface InputFieldLinkedinCompanyIdProps {
  name: string
}

const transform: InputFieldTransform = {
  input: (value) => {
    return value
  },
  output: (event) => {
    const companyId = getLinkedinExternalId(event.target.value)
    if (!companyId) {
      return event
    }

    event.target.value = companyId.toString()
    return event
  },
}

export default function InputFieldLinkedinCompanyId({ name }: InputFieldLinkedinCompanyIdProps) {
  return (
    <InputFieldText
      consistencyGuideName={'linkedin'}
      InputProps={{
        endAdornment: (
          <InputEndAdornmentHref
            field={name}
            transform={getLinkedInCompanyUrl}
          />
        ),
      }}
      label={'LinkedIn company ID'}
      name={name}
      transform={transform}
    />
  )
}
