import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import React from 'react'

import Markdown from '../../common/markdown'
import { getFromConsistencyGuide } from '../../features/consistency-guide'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(-1, 0),
}))

const StyledConsistencyGuidePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 350,
}))

interface ConsistencyGuideButtonProps {
  label?: string
  consistencyGuideName?: string
}

export default function ConsistencyGuideButton({
  label,
  consistencyGuideName,
}: ConsistencyGuideButtonProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  if (!label || !consistencyGuideName) {
    return null
  }

  const handleShowHelp = () => {
    enqueueSnackbar(undefined, {
      key: 'consistency-guide-snackbar',
      content: (snackbarId) => (
        <StyledConsistencyGuidePaper>
          <Stack
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}>
            <Typography variant={'h5'}>{label}</Typography>
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Markdown source={getFromConsistencyGuide(consistencyGuideName)} />
        </StyledConsistencyGuidePaper>
      ),
      preventDuplicate: true,
      persist: true,
    })
  }

  return (
    <StyledIconButton onClick={handleShowHelp}>
      <HelpOutlineIcon fontSize={'inherit'} />
    </StyledIconButton>
  )
}
