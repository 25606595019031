import Typography from '@gain/components/typography'
import { useOpenLink } from '@gain/utils/router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { styled, SxProps } from '@mui/material/styles'
import React from 'react'
import { generatePath } from 'react-router'

import { ROUTE_ASSET_KEY_FACTS_PATH } from '../routes/route-asset/route-asset-path'
import { CREDIT_GENERAL_PAGE_PATH } from '../routes/route-credit'
import { LENDER_PROFILE_PAGE_PATH } from '../routes/route-lender/route-lender-path'

const StyledCmsLink = styled(Typography)({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

type LinkType = 'asset' | 'lender' | 'credit'

const LinkMap: Record<LinkType, string> = {
  asset: ROUTE_ASSET_KEY_FACTS_PATH,
  lender: LENDER_PROFILE_PAGE_PATH,
  credit: CREDIT_GENERAL_PAGE_PATH,
}

export interface CmsLinkProps {
  id: number | string | null | undefined
  type: LinkType
  label: string | undefined | null
  sx?: SxProps
}

export default function CmsLink({ id, type, label, sx }: CmsLinkProps) {
  const openLink = useOpenLink()

  if (!id || !label) {
    return null
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    openLink(generatePath(LinkMap[type], { id }), e)
  }

  return (
    <StyledCmsLink
      color={'text.primary'}
      onClick={handleClick}
      sx={sx}
      variant={'body2'}
      noWrap>
      {label}
      <ChevronRightIcon />
    </StyledCmsLink>
  )
}
